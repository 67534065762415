exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rent-js": () => import("./../../../src/pages/rent.js" /* webpackChunkName: "component---src-pages-rent-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-pages-tours-la-js": () => import("./../../../src/pages/tours/la.js" /* webpackChunkName: "component---src-pages-tours-la-js" */),
  "component---src-pages-tours-mexico-js": () => import("./../../../src/pages/tours/mexico.js" /* webpackChunkName: "component---src-pages-tours-mexico-js" */),
  "component---src-pages-travels-js": () => import("./../../../src/pages/travels.js" /* webpackChunkName: "component---src-pages-travels-js" */),
  "component---src-templates-single-mexico-js": () => import("./../../../src/templates/single-mexico.js" /* webpackChunkName: "component---src-templates-single-mexico-js" */),
  "component---src-templates-single-travel-js": () => import("./../../../src/templates/single-travel.js" /* webpackChunkName: "component---src-templates-single-travel-js" */)
}

